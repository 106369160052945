import {bus} from "@/helpers/bus";

import Utils from "@/helpers/utils";
import Dh from "@/helpers/date.helper";

import CustomFilter from "@/components/Filters/Custom/index";
import Agents from "@/components/Filters/Agent/index";

import TwilioNumberService from '@/services/twilio.number.service';

const service = new TwilioNumberService();

export default {
  name: 'numbers',

  components: {
    CustomFilter,
    Agents,
  },

  data() {
    return {
      max: Infinity,

      createdModal: false,
      numbersModal: false,
      deleteModal: false,

      deleteNumber: null,
      deleteWarning: false,

      numberQuery: '',
      searchNumbers: [],
      numbers: [],
      number: {
        name: '',
        forwardEnabled: false,
        forwardNumbers: '',
        phoneNumber: null,
      },

      perPage: 10,
      totalRows: 0,
      currentPage: 1,

      isLoading: false,

      // Filters
      searchTerm: '',
      numberTypes: '',
      agentId: '',
    }
  },

  computed: {
    assignOptions() {
      return [{
        value: 'USER',
        text: this.$t('agent'),
      }, {
        value: 'HUNTGROUP',
        text: this.$t('hunt_group'),
      }, {
        value: 'QUEUE',
        text: this.$t('queue'),
      }, {
        value: 'ACTION_PLAN',
        text: this.$t('action_plan'),
      }, {
        value: 'SYSTEM',
        text: this.$t('system'),
      }, {
        value: 'CAMPAIGN',
        text: this.$t('campaign'),
      }, {
        value: 'NONE',
        text: this.$t('none'),
      }];
    }
  },

  async mounted() {
    await this.list();
  },

  methods: {
    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const filters = {
        search: this.searchTerm,
        agent: this.agentId,
        types: this.numberTypes,
      }

      this.numbers = [];

      const res = await service.list(this.perPage, skip, filters);
      if (res && !res.error) {
        this.numbers = res.data.numbers;
        this.totalRows = res.data.count;
        this.max = res.data.max;
      }
    },

    async showRemove(number) {
      this.deleteWarning = number.assignee.type !== 'NONE';
      this.deleteNumber = number;
      this.deleteModal = true;
    },

    async goToManage(number) {
      await this.$router.push({name: 'SettingCallCenterManageNumber', params: {numberId: number._id}});
    },

    async searchNumber(pattern) {
      const res = await service.search(pattern);
      if (res && !res.error) {
        this.searchNumbers = res.data;
      }
    },

    buyNumber() {
      if (!this.number.phoneNumber) return;
      this.numbersModal = false;
      this.createdModal = true;
    },

    async remove() {
      this.isLoading = true;

      const res = await service.delete(this.deleteNumber.sid);
      if (res && !res.error) {
        const index = this.numbers.findIndex(n => n.sid === this.deleteNumber.sid);
        if (index !== -1) {
          this.numbers.splice(index, 1);
        }

        this.$notify({type: 'success', message: this.$t('remove_number_success')});

        this.deleteModal = false;
        this.deleteNumber = null;
      }
      else {
        this.$notify({type: 'error', message: this.$t('remove_number_error')});
      }

      this.isLoading = false;
    },

    async save() {
      if (this.number.name.length < 3) {
        return;
      }

      this.isLoading = true;

      const res = await service.create(this.number);
      if (res && !res.error) {
        this.createdModal = false;
        this.numbers.push(res.data);
      }

      this.isLoading = false;
    },

    onInitAgent(value) {
      this.agentId = value;
    },

    async onSelectAgent(agentId) {
      this.agentId = agentId;
      await this.list();
    },

    async onSelectNumberType(numberType) {
      this.numberTypes = numberType ? numberType.value : '';
      await this.list();
    },

    tooltipDate(date) {
      return Dh.customFormat(date, 'D MMM YYYY, h:mm A');
    },

    async setPerPage(num) {
      this.perPage = +(num);
      bus.$emit('HIDE_ALL_MODALS');
      await this.list();
    },

    async next() {
      return this.list(this.currentPage + 1);
    },

    async prev() {
      return this.list(this.currentPage - 1);
    },
  },

  watch: {
    async numberQuery(newVal) {
      if (newVal.length >= 3) {
        await this.searchNumber(newVal);
      }
    },

    createdModal(newVal) {
      if (newVal === false) {
        this.number = {name: '', forwardEnabled: false, forwardNumbers: '', phoneNumber: null};
        this.searchNumbers = [];
        this.numberQuery = '';
      }
    },

    numbersModal(newVal) {
      if (newVal === false) {
        this.searchNumbers = [];
        this.numberQuery = '';
      }
    },

    async searchTerm() {
      await this.list();
    },
  },

  filters: {
    assignedTo(assignee) {
      if (assignee.huntGroup) {
        return assignee.huntGroup.name;

      } else if (assignee.queue) {
        return assignee.queue.Name;

      } else if (assignee.user) {
        return `${assignee.user.FirstName} ${assignee.user.LastName}`;

      } else {
        return 'N/A';
      }
    },

    formatUSNumber(number) {
      if (number) {
        return `${Utils.formatUSNumber(number)}`;
      }
      return 'N/A';
    },

    formatDate(date) {
      if (date) {
        return Dh.formatDate(date);
      }
      return 'N/A';
    },
  },
}